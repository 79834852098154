<template>
  <div class="backMenu RolaList">
    <div class="">
      <el-card class="box-card">
        <div class="MenuTop">
          <div class="sch">
            <el-input
              v-model="MenuApi.name"
              placeholder="请输入角色名称"
              clearable
              maxlength="16"
            >
            </el-input>
            <el-button type="primary" class="btn" @click="inquire()"
              >查询</el-button
            >
          </div>
          <!-- v-if="BtnJurisdiction('/Auth/roleHandle')" -->
          <div class="btn">
            <el-button
              type="primary"
              @click="toAddBack"
              v-if="allJurisdiction.RolaList120"
              >创建角色</el-button
            >
          </div>
        </div>
        <!-- 角色列表 -->
        <div class="MenuList scorr-roll">
          <el-table :data="rolaList.data" border style="width: 100%">
            <el-table-column
              prop="date"
              label="序号"
              min-width="80"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.id }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="创建时间"
              min-width="300"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.create_time }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="角色名称"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="角色描述"
              min-width="300"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.describe }}
                </div>
              </template>
            </el-table-column>
            <!--    v-if="BtnJurisdiction('/Auth/allAuth')" -->
            <el-table-column
              prop="address"
              label="操作"
              width="400"
              align="center"
            >
              <template slot-scope="{ row }">
                <!-- v-if="row.id != 1||$cookie.get(`role`)==`超级管理员`" -->
                <div class="rootBtn">
                  <!-- v-if="
                      BtnJurisdiction('/Auth/allAuth') &&
                      BtnJurisdiction('/Auth/roleHandle')
                    " -->
                  <button
                    @click="toDetails(row)"
                    v-if="allJurisdiction.RolaList121"
                  >
                    详情
                  </button>
                  <button
                    @click="toCompile(row)"
                    v-if="
                      row.is_super != 1 &&
                      allJurisdiction.RolaList122 &&
                      row.can_edit
                    "
                  >
                    编辑
                  </button>
                  <button
                    @click="deleted(row)"
                    v-if="
                      row.is_super != 1 &&
                      allJurisdiction.RolaList124 &&
                      row.can_edit
                    "
                  >
                    删除
                  </button>
                  <button
                    @click="transfer(row)"
                    v-if="
                      row.is_super != 1 &&
                      allJurisdiction.RolaList123 &&
                      row.can_edit
                    "
                    class="colored"
                  >
                    账号转移
                  </button>
                </div>
                <!-- <div v-else>
                  <span>超级管理员不可编辑</span>
                </div> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="paging">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="rolaList.per_page"
            layout="total,prev, pager, next, jumper"
            :total="rolaList.total"
            :current-page="MenuApi.page"
          >
          </el-pagination>
        </div>
        <!-- 新建弹框 -->
        <div>
          <el-dialog title="提示" :visible.sync="isAddBackPerson" width="600px">
            <div class="AddMenu scorr-roll">
              <div class="AddMenu-1-box">
                <div>
                  <el-form label-width="120px">
                    <el-form-item label="角色名称:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.name"
                          placeholder="角色名"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="isAddBackPerson = false">取 消</el-button>
              <el-button type="primary" @click="AddNotarize">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-card>
      <el-dialog
        :close-on-click-modal="false"
        title="删除角色提醒"
        :visible.sync="dialogVisibleDeleted"
        width="30%"
      >
        <span v-if="this.roleAdminNum == 0"
          >确定要删除角色【{{
            dialogName
          }}】吗？删除后角色不可恢复，请谨慎操作。</span
        ><span v-else
          >你无法删除角色【{{
            dialogName
          }}】，因为存在账号已分配当前角色情形。</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="dialogVisibleDeleted = false"
            v-if="this.roleAdminNum == 0"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmDel()"
            v-if="this.roleAdminNum == 0"
            >确 定</el-button
          >
          <el-button type="primary" @click="dialogVisibleDeleted = false" v-else
            >知道了</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="账号转移提醒"
        :visible.sync="dialogFormVisible"
        width="422px"
        :close-on-click-modal="false"
        @close="handleClose()"
      >
        <el-form>
          <div v-if="roleAdminNum > 0">
            您确定要将当前角色【{{ dialogName }}】已关联的<span
              class="dialog-span"
            >
              {{ roleAdminNum }} </span
            >条账号进行角色转移操作？请选择需要转移目标角色：
            <el-form-item label="选择角色" :label-width="'120'">
              <el-select v-model="objective" placeholder="请选择角色">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in transferList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <span class="hint" v-if="!$route.query.steured && isHint"
              >请先选择一个角色</span
            >
          </div>
          <div v-if="roleAdminNum == 0">
            当前角色【{{ dialogName }}】尚未关联账号，您无法使用账号转移功能。
          </div>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" v-if="roleAdminNum > 0"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmTransfer()"
            v-if="roleAdminNum > 0"
            >确 定</el-button
          >
          <el-button
            type="primary"
            @click="dialogFormVisible = false"
            v-if="roleAdminNum == 0"
            >知 道 了</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapMutations, mapState } = createNamespacedHelpers(
  "power",
  "getMenuList"
); //权限vuex公共库
export default {
  data() {
    return {
      isEdit: false,
      rolaList: [],
      isHint: false,
      MenuApi: {
        name: "",
        page: 1,
      },
      isAddBackPerson: false, //弹框
      addData: {
        name: "", //名称
        ids: [], //权限集合
        roleId: "", //角色id 修改必须
      },
      PowerDataList: [], //储存选中权限
      allPowerList: [], //所有权限
      dialogVisibleDeleted: false, //删除弹出框
      dialogName: "",
      dialogFormVisible: false, //账号转移弹出框
      objective: "", //目标角色
      roleAdminNum: 0, //获取角色下管理员账号数量
      roleId: 0, //角色id
      // RolaListPage:1,
      transferList: [], //转移列表
      
    };
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.MenuApi= this.detailsSeach[router];
    }
    this.getrole();
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction", "detailsSeach"]),
    ...mapState(["RolaListPage"]),
  },
  methods: {
    ...mapActions([
      "getroleList",
      "getallAuth",
      "postRoleHandle",
      "getMenuList",
      "getroleAdmin",
      "postroleDel",
      "postroleTransfer",
      "getconsultRole",
    ]),
    ...commonIndex.mapActions(["allBotton"]),
    ...commonIndex.mapMutations(['changeSeach']),
    // ...mapMutations(["cgRolaListPage"]),
    // 重新获取已有权限
    getArea() {
      this.allBotton();
    },
    // 查询
    inquire() {
      // this.cgRolaListPage(1);
      this.getrole();
    },
    // 编辑角色
    async redactMake() {
      if (this.addData.name == "") {
        this.$message({
          message: "角色名称不能为空",
          type: "warning",
        });
        return;
      }
      this.addData.ids = this.allPowerList.ids;
      if (this.addData.roleId) {
        this.allPowerList.menu.forEach((item) => {
          this.addData.ids.push(item.id);
        });
      }
      let data = await this.postRoleHandle(this.addData);
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
        this.getMenuList();
        this.getArea();
        this.isEdit = false;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 新建角色
    async AddNotarize() {
      if (!this.addData.name) {
        this.$message({
          message: "角色名称不能为空",
          type: "warning",
        });
        return;
      }
      let data = await this.postRoleHandle(this.addData);
      if (data.code == 200) {
        this.getrole();
        this.$message({
          message: data.message,
          type: "success",
        });
        this.isAddBackPerson = false;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 获取角色列表
    async getrole() {
      let data = await this.getroleList(
        this.MenuApi
      );

      let router = this.$router.history.current.path.substr(1);
      let save = {...this.MenuApi};
      save.router = router;
      this.changeSeach(save);

      //console.log(data.data);
      this.rolaList = data.data;
    },
    // 角色编辑
    async toEditBtn(val) {

      this.addData = {
        name: val.name, //名称
        ids: [], //权限集合
        roleId: val.id, //角色id 修改必须
      };
      let data = await this.getallAuth({ roleId: val.id });
      if (data.code == 200) {
        this.allPowerList = data.data;
        this.isEdit = true;
        return;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }

      // this.$message.error("请联系后台人员");
    },
    // 去新建
    toAddBack() {
      this.$router.push({
        path: "/permission",
        query: { type: 1 },
      });
    },
    // 详情
    toDetails(row) {
      this.$router.push({
        path: "/permission",
        query: { id: row.id, type: 2 },
      });
    },
    // 编辑
    toCompile(row) {
      this.$router.push({
        path: "/permission",
        query: { id: row.id, type: 3 },
      });
    },
    // 翻页
    handleCurrentChange(e) {
      // this.cgRolaListPage(e);
      this.MenuApi.page = e;
      // this.RolaListPage=e
      this.getrole();
    },
    // 删除
    async deleted(row) {
      this.dialogName = row.name;
      this.roleId = row.id;
      let data = await this.getroleAdmin({ role_id: row.id });
      this.roleAdminNum = data.data.length;
      this.dialogVisibleDeleted = true;
    },
    // 确认删除
    async confirmDel() {
      let data = await this.postroleDel({
        role_id: this.roleId,
        del_status: "1",
      });
      if (data.code == "200") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.dialogVisibleDeleted = false;
        this.getrole();
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 账号转移
    async transfer(row) {
      this.isHint = false;
      this.roleId = row.id;
      let data = await this.getroleAdmin({ role_id: row.id });
      let res = await this.getconsultRole();
      let num = res.data.filter((item) => {
        return item.id != row.id && item.is_super != 1;
      });
      if (data.code != "200") {
        this.$message({
          message: data.message,
          type: "warning",
        });
        return;
      }
      this.transferList = num;
      this.roleAdminNum = data.data.length;
      this.dialogFormVisible = true;
      this.dialogName = row.name;
    },
    // 确认转移
    async confirmTransfer() {
      if (this.objective == "") {
        this.isHint = true;
        return;
      }
      let data = await this.postroleTransfer({
        role_id: this.roleId,
        new_role_id: this.objective,
      });
      if (data.code == "200") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.dialogFormVisible = false;
        this.getrole();
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    handleClose() {
      this.objective = "";
    },
    // act：del
    delRola(data) {},
    // 按钮权限
    BtnJurisdiction,
  },
};
</script>
<style lang='scss' scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-checkbox__label {
  color: black;
}
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
  margin-top: 5px;
}
.el-tabs__item {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style lang="scss" scoped>
.backMenu {
  width: 100%;
  .MenuTop {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sch {
      display: flex;
    }
    .hint-title {
      font-size: 15px;
      font-weight: bold;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .MenuList {
    margin-top: 20px;
    width: 100%;
    max-height: 65vh;
    .exceed {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rootBtn {
      button {
        border: none;
        background: none;
        cursor: pointer;
        color: #06b7ae;
        font-size: 14px;
        padding: 0 15px;
      }
    }
  }
  .paging {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
  .AddMenu {
    width: 100%;
    max-height: 500px;
    .AddMenu-1-box {
      display: flex;
      justify-content: center;
      .inputbox {
        width: 400px;
      }
    }
  }
  // 后台权限设置
  .Edit {
    width: 100%;
    max-height: 60vh;
    .inputbox {
      width: 400px;
    }
    .menuPower {
      width: 100%;
      max-height: 325px;
      .menuPower-title {
        font-size: 20px;
        font-weight: bold;
      }
      .menu-top {
        margin-top: 30px;
        .tabs-menu {
          width: 100%;
          height: 260px;
        }
        .ss {
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .btnPower {
      margin-top: 20px;
      width: 100%;
      max-height: 300px;
      .btnPower-title {
        font-size: 20px;
        font-weight: bold;
      }
      .menu-top {
        margin-top: 30px;
        .tabs-menu {
          width: 100%;
          max-height: 260px;
        }
        .ss {
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.colored {
  color: #ed8e35 !important;
}
.hint {
  color: #f60;
}
.dialog-span {
  color: #06b7ae;
  font-size: 18px;
  font-weight: bold;
}
::v-deep .el-form {
  line-height: 30px;
}
::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  background-color: #f2f3f5 !important;
}
::v-deep .el-table--border {
  border-radius: 10px;
  overflow: hidden;
}
::v-deep .el-select {
  width: 300px;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
  margin-top: 25px;
}
.backMenu .MenuList .exceed {
  cursor: default;
}
</style>
